<!-- main page content -->
<div class="main-container container pt-70 pb-70 profile">
  <div class="row bgcard">
    <!-- profile picture -->
    <div class="row mt-4">
      <div class="col-12">
        <div class="card card-light shadow-sm">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2 col-5">
                <figure class="avatar avatar-100 rounded mx-auto">
                  <img
                    *ngIf="this.childProfile?.gender?.toLowerCase() === 'male'"
                    src="/assets/img/maleKid.png"
                    class="profileImg"
                  />
                  <img
                    *ngIf="
                      this.childProfile?.gender?.toLowerCase() === 'female'
                    "
                    src="/assets/img/femaleKid.png"
                    class="profileImg"
                  />
                </figure>
              </div>
              <div class="col-md-10 mt-3 col-7 profile-flex">
                <h5 class="mb-0">
                  {{
                    this.childProfile?.firstName +
                      " " +
                      this.childProfile?.lastName
                  }}
                </h5>
                <p class="text-opac mt-2">{{ this.childProfile?.address }}</p>
                <div class="star-flex">
                  <p class="text-opac">Stars Eraned</p>

                  <div class="chip active">
                    <img style="margin-top: -7px" src="/assets/img/star.svg" />
                    {{ this.childProfile?.starsEarned }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- profile information -->
    <div class="row mb-3 mt-3">
      <div class="col">
        <h5 class="mb-0">Basic Information</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-light shadow-sm mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    [value]="this.childProfile?.firstName"
                    id="firstName"
                  />
                  <label for="firstName">First Name</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    [value]="this.childProfile?.lastName"
                    id="lastName"
                  />
                  <label for="lastName">Last Name</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    [value]="this.childProfile?.parentName"
                    id="parentName"
                  />
                  <label for="parentName">Parent Name</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    [value]="this.childProfile?.gender"
                    id="gender"
                  />
                  <label for="gender">Gender</label>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="form-group form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    [value]="this.childProfile?.dateOfBirth | date : 'MMM d, y'"
                    id="DOB"
                  />
                  <label for="DOB">Date Of Birth</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- add edit address form -->
    <div class="row mb-3">
      <div class="col">
        <h5 class="mb-0">Address</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-light shadow-sm mb-4">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-6 mb-3 mt-3">
                <div class="form-group form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    [value]="this.childProfile?.email"
                    id="email"
                  />
                  <label for="email">Email</label>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-3 mt-3">
                <div class="form-group form-floating">
                  <input
                    type="text"
                    class="form-control"
                    [value]="this.childProfile?.address"
                    id="address"
                  />
                  <label class="form-control-label" for="address"
                    >Address</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- change password -->
    <div class="row mb-3">
      <div class="col">
        <h5 class="mb-0">Subscription Information</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card card-light shadow-sm mb-4">
          <div class="card-body">
            <div class="row h-100">
              <div class="col-12 col-md-6 col-lg-4 subs-flex">
                <h5>Subscription</h5>
                <div class="chip info">
                  {{ this.childProfile?.subscription }}
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4 subs-flex">
                <h5>Subscription Status</h5>
                <div
                  *ngIf="this.childProfile?.hasActiveSubscription"
                  class="chip active"
                >
                  Active
                </div>
                <div
                  *ngIf="!this.childProfile?.hasActiveSubscription"
                  class="chip disabled"
                >
                  Expired
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4 subs-flex">
                <h5>Subscription Expiration</h5>
                <div
                  *ngIf="this.childProfile?.subscriptionExpiration === null"
                  class="chip warning"
                  data-mdb-ripple-color="dark"
                >
                  Unknown
                </div>
                <div
                  *ngIf="this.childProfile?.subscriptionExpiration !== null"
                  class="chip warning"
                  data-mdb-ripple-color="dark"
                >
                  {{
                    this.childProfile?.subscriptionExpiration
                      | date : "MMM d, y"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row h-100 mb-4">
    <div class="col-12 d-grid">
      <a
        target="_self"
        class="btn btn-lg btn-default shadow-sm"
        (click)="updateProfile()"
        >Update</a
      >
    </div>
  </div> -->
  </div>
</div>
<!-- main page content ends -->
