import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss'],
})
export class WelcomeModalComponent implements OnInit {
  @Input() public data: any;
  constructor(private ngbModal: NgbModal) {}

  ngOnInit() {}

  dismissModal() {
    this.ngbModal.dismissAll('dismiss');
  }
  onConfirm() {
    this.ngbModal.dismissAll('confirm');
  }
}
