<footer class="footer">
  <div class="text-center p-3">
    <p>
      <a
        class="terms"
        data-bs-toggle="modal"
        data-bs-target="#exampleModalTermsOfUse"
        >Terms of Use</a
      >
      <a data-bs-toggle="modal" data-bs-target="#exampleModalPrivacyPolicy">
        Privacy Policy</a
      >
    </p>
    <div>Copyright &copy; {{ year }} - KidsGate</div>
  </div>
</footer>
<div
  class="modal fade"
  id="exampleModalPrivacyPolicy"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Privacy Policy</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body"><app-privacy-policy></app-privacy-policy></div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="exampleModalTermsOfUse"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Terms Of Use</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body"><app-terms-of-use></app-terms-of-use></div>
    </div>
  </div>
</div>
