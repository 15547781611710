<div class="games-component">
  <div class="row">
    <div class="col-xs-12 iframeWrapper">
      <div class="card gameCard">
        <iframe [src]="safeUrl"></iframe>
      </div>
    </div>
    <div class="container mt-3">
      <div class="row justify-content-center">
        <div class="col-xs-12 col-md-6">
          <div>
            <p style="text-align: left; padding-left: 2%">
              What did you learn after playing {{ selectedOption.title }}?
              Please share your thoughts.
            </p>
          </div>
          <div>
            <div
              class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm"
            >
              <input
                autofocus
                id="answer"
                type="text"
                [(ngModel)]="feedback"
                class="form-control border-0"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-primary rounded-pill px-4"
                  [disabled]="isLoading || !feedback"
                  (click)="onSubmitFeedback()"
                >
                  <span
                    *ngIf="isLoading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span *ngIf="!isLoading">Send</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-group card-group-scroll mt-2">
      <ng-container *ngFor="let item of gameList">
        <div class="card m-1 item" (click)="setGame(item)">
          <div class="card">
            <img
              style="height: 20vh"
              [src]="item.thumbnil"
              class="card-img-top"
            />
            <div class="card-body">
              <h5 class="card-title">{{ item.title }}</h5>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
