<div class="confirmation-modal">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">Welcome</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="dismissModal()"
    ></button>
  </div>
  <div class="modal-body">
    <!-- In your component.html -->
    <div class="image-container">
      <img src="../../../assets/img/welcomeBack.png" alt="Welcome Back" />
      <div class="overlay-text">{{ data }}</div>
    </div>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" (click)="dismissModal()" class="btn btn-secondary">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="onConfirm()">
      Confirm
    </button>
  </div> -->
</div>
