<div class="container-fluid">
  <app-spinner></app-spinner>
  <header class="row">
    <app-navigation class="col"></app-navigation>
  </header>
  <main class="row">
    <router-outlet></router-outlet>
  </main>
  <footer class="row">
    <app-footer class="col padding"> </app-footer>
  </footer>

  <div
    class="notification-panel"
    *ngIf="
      showNotifications && childNotifications && childNotifications.length > 0
    "
  >
    <div class="card position-relative border-0">
      <div class="card-body p-0">
        <div class="d-flex justify-content-between align-items-center p-1">
          <div class="notificationHeading color-deepblue">Notifications</div>
          <img src="assets/img/Close.svg" (click)="showNotifications = false" />
        </div>
      </div>
      <div class="overflow-auto scrollbar" style="height: 20rem">
        <ul>
          <li *ngFor="let item of childNotifications">
            <div
              class="notification-card position-relative read border-bottom pt-8"
            >
              <div>
                <div class="d-flex">
                  <div class="me-3 flex-1">
                    <div class="subject color-deepblue">{{ item.subject }}</div>
                    <div class="body color-deepblue">
                      {{ item.message }}
                    </div>
                    <div
                      class="color-light-grey font-w-700 text-center font-w-700 font-12"
                      style="margin-bottom: -8px"
                    >
                      {{ item.dateCreated | date }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <button
    type="button"
    class="btn notification-btn"
    *ngIf="
      !showNotifications && childNotifications && childNotifications.length > 0
    "
    (click)="showNotifications = true"
  >
    <img src="/assets/img/notification.svg" />
  </button>
</div>
