export interface Entertainment {
  id: number;
  stars: number;
  description?: string;
  coupon: string;
  status: RewardStatus;
}
export enum RewardStatus {
  Collected,
  Enabled,
  Disabled,
}
