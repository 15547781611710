<div class="expiration-modal">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">
      Subscription Expiration
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="dismissModal()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="image-container">
      <img src="/assets/img/invalidSub.png" alt="Invalid subscription" />
      <div class="overlay-text">
        <button (click)="dismissModal()">Go Back</button>
      </div>
    </div>
  </div>
</div>
