import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import Painterro from 'painterro';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { Activities } from './model/activities.model';

@Component({
  selector: 'app-painting',
  templateUrl: './painting.component.html',
  styleUrls: ['./painting.component.scss'],
})
export class PaintingComponent implements OnInit, OnDestroy {
  @ViewChild('painterroContainer') painterroContainer: ElementRef;
  private readonly MAX_IMAGE_SIZE_BYTES = 5 * 1024 * 1024;
  public currentDay?: string;
  public weekDays: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  public todayAssignedActivity: Activities;
  public dayOfWeek?: string | null = '';
  public panterroInstance: any;
  public isInProgress: boolean = false;
  constructor(
    public dataService: DataService,
    public userService: UserService,
    public notification: NotificationService,
    private renderer: Renderer2
  ) {
    const currentDate = new Date();
    this.currentDay = currentDate.toLocaleDateString('en-US', {
      weekday: 'long',
    });
    this.loadScript('assets/scripts/painterro-1.2.78.min.js')
      .then(() => {
        const self = this;
        this.panterroInstance = Painterro({
          activeColor: '#00ff00',
          id: 'painterroContainer',
          toolbarPosition: 'top',
          hiddenTools: ['close', 'settings', 'resize'],
          toolbarHeightPx: 54,
          buttonSizePx: 46,
          defaultTool: 'brush',
          saveHandler: function (image: any, done: any) {
            if (image?.asBlob().size > self.MAX_IMAGE_SIZE_BYTES) {
              notification.showWarningMessage(
                'Image size exceeds the maximum limit.',
                'Warning'
              );
              done();
              return;
            }
            const acceptedImageTypes = [
              'image/jpeg',
              'image/jpg',
              'image/png',
              'undefined',
            ];
            if (
              image &&
              !(
                acceptedImageTypes.includes(image?.getOriginalMimeType()) ||
                image?.getOriginalMimeType() == undefined
              )
            ) {
              notification.showWarningMessage(
                'Invalid file type. Please select an image file, i.e., jpeg, jpg or png',
                'Warning'
              );
              done();
              return;
            }

            var formData = new FormData();
            formData.append('file', image.asBlob());
            var todayAssignedActivity =
              self.todayAssignedActivity?.activityHistoryId;
            notification.showSpinner.next(true);
            dataService
              .addFile(
                `api/Kids/KidsActivities/AddImage/${todayAssignedActivity}`,
                formData
              )
              .subscribe((Drawing: any) => {
                notification.showSpinner.next(false);
                notification.showSuccessMessage(
                  'Drawing Saved Successfully!',
                  ''
                );
                done();
              });
          },
          onChange: function (res: any) {
            const acceptedImageTypes = [
              'image/jpeg',
              'image/jpg',
              'image/png',
              'undefined',
            ];
            if (
              res &&
              res?.image &&
              !(
                acceptedImageTypes.includes(
                  res?.image?.getOriginalMimeType()
                ) || res?.image?.getOriginalMimeType() == undefined
              )
            ) {
              notification.showWarningMessage(
                'Invalid file type. Please select an image file, i.e., jpeg, jpg or png',
                'Warning'
              );
            }
            if (res?.image?.asBlob().size > self.MAX_IMAGE_SIZE_BYTES) {
              notification.showWarningMessage(
                'Image size exceeds the maximum limit.',
                'Warning'
              );
            }
          },
        }).show();
        console.log('Painterro Instance ', this.panterroInstance);
      })
      .catch((error) => {
        // Handle errors if the script fails to load or execute
        console.error('Error loading script:', error);
      });
  }

  initPainterro(imageURL: any) {
    this.panterroInstance.show(imageURL);
  }
  saveDrwing() {
    if (this.panterroInstance) {
      let saveObj = this.panterroInstance?.tools?.find(
        (x: any) => x.name == 'save'
      );
      saveObj.activate();
    }
  }
  closePainterro() {
    if (this.panterroInstance) {
      let openObj = this.panterroInstance?.tools?.find(
        (x: any) => x.name == 'open'
      );
      openObj.activate();
    }
  }
  ngOnInit(): void {
    this.userService.authService.user$.subscribe((res) => {
      if (!!res && res?.sub) {
        this.getDrawingActivityByDay();
      }
    });
  }

  loadScript(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = url;

      // Script load event
      script.onload = () => {
        resolve();
      };

      // Script error event
      script.onerror = (error: Event) => {
        reject(error);
      };

      // Append the script to the <head> element
      this.renderer.appendChild(document.head, script);

      return new Promise<void>((resolve, reject) => {
        // Load scripts here and resolve the promise when they are loaded successfully.
        // If there's an error during script loading, reject the promise.
      });
    });
  }

  public getDrawingActivityByDay(): void {
    this.isInProgress = true;
    this.dataService
      .getAll<Activities[]>(
        `api/Kids/KidsActivities/GetDrawingActivitiesByDay/${this.userService.userProfile.userId}`
      )
      .subscribe((data) => {
        this.isInProgress = false;
        if (data && data.length > 0) {
          this.todayAssignedActivity = data[0];
          let objectURL =
            'data:image/png;base64,' + this.todayAssignedActivity?.image;
          if (this.todayAssignedActivity?.image?.length > 0) {
            this.initPainterro(objectURL);
          }
        }
      });
  }
  ngOnDestroy(): void {
    if (this.panterroInstance) {
      this.panterroInstance = null;
    }
  }
}
