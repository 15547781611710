import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { Nutrition } from './model/Nutrition.model';
import { interval, take } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-nutrition',
  templateUrl: './nutrition.component.html',
  styleUrls: ['./nutrition.component.scss'],
})
export class NutritionComponent implements OnInit {
  private readonly MAX_IMAGE_SIZE_BYTES = 5 * 1024 * 1024;

  public todayNutrition: Nutrition = {
    activityHistoryId: 0,
    childId: 0,
    dayOfWeek: '',
    description: '',
    isCompleted: false,
    name: '',
    schedule: '',
    stars: 0,
    starsEarned: 0,
    image: '',
    historyImage: '',
    answer: '',
  };
  public recipeSteps: string[];
  public currentDay?: string;
  public isLoading: boolean;
  selectedImage: any = null;
  formData = new FormData();
  public isInProgress: boolean = false;
  public weekDays: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  constructor(
    public dataService: DataService,
    public user: UserService,
    public notification: NotificationService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    const currentDate = new Date();
    this.currentDay = currentDate.toLocaleDateString('en-US', {
      weekday: 'long',
    });
  }
  ngOnInit(): void {
    this.getNutritionByDay();
  }
  public getNutritionByDay(): void {
    this.notification.showSpinner.next(true);
    this.isInProgress = true;
    this.dataService
      .getById<Nutrition>(
        `api/Kids/KidsNutrition/GetNutritionByDay`,
        this.user.userProfile.userId
      )
      .subscribe((data) => {
        this.notification.showSpinner.next(false);
        this.isInProgress = false;
        if (data && data.childId > 0) {
          this.todayNutrition = data;
          if (data.image !== null) {
            data.image = 'data:image/png;base64,' + data?.image;
          }
          if (data.historyImage !== null) {
            let objectURL = 'data:image/png;base64,' + data?.historyImage;
            this.selectedImage = objectURL;
          }
          if (data.recipeStepsJson) {
            this.recipeSteps = JSON.parse(data.recipeStepsJson ?? '');
          }
        }
      });
  }
  public onFileSelected(event: any): void {
    this.selectedImage = null;
    this.formData.delete('file');
    const file = event.target.files[0];
    if (file) {
      if (file.size > this.MAX_IMAGE_SIZE_BYTES) {
        this.notification.showWarningMessage(
          'Image size exceeds the maximum limit.',
          'Warning'
        );
        return;
      }

      const acceptedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!acceptedImageTypes.includes(file.type)) {
        this.notification.showWarningMessage(
          'Invalid file type. Please select an image file, i.e., jpeg, jpg or png',
          'Warning'
        );
        return;
      }
      this.selectedImage = URL.createObjectURL(file);
      this.formData.append('file', file);
    }
  }

  public onSubmit(): void {
    if (this.formData.has('file')) {
      if (this.todayNutrition.activityHistoryId > 0) {
        this.notification.showSpinner.next(true);
        this.dataService
          .addFile(
            `api/Kids/KidsNutrition/AddImage/${this.todayNutrition.activityHistoryId}`,
            this.formData
          )
          .subscribe((Drawing: any) => {
            this.todayNutrition.isCompleted = true;
            this.notification.showSpinner.next(false);
            this.notification.showSuccessMessage(
              'Meal uploaded Successfully!',
              ''
            );
          });
      }
    }
  }
  public onSubmitAnswer(): void {
    if (this.todayNutrition.answer !== '') {
      if (this.todayNutrition.activityHistoryId > 0) {
        this.isLoading = true;
        this.dataService
          .update(`api/Kids/KidsNutrition/SaveActivityAnswer`, {
            Id: this.todayNutrition.activityHistoryId,
            Answer: this.todayNutrition.answer,
          })
          .subscribe(() => {
            this.isLoading = false;
            (this.todayNutrition.answer = ''),
              this.notification.showSuccessMessage(
                'Thank you For Feedback',
                ''
              );
          });
      }
    }
  }
  private openModal(): void {
    $('#confirmationModal').modal('show');
  }
  public cancelModal(): void {
    this.todayNutrition.isCompleted = false;
    $('#confirmationModal').modal('hide');
  }
  public onCheckboxChange(): void {
    if (!this.todayNutrition.isCompleted) {
      this.openModal();
    }
  }
  public onConfirm(): void {
    if (this.todayNutrition.activityHistoryId > 0) {
      interval(20)
        .pipe(take(200))
        .subscribe(() => {
          this.startAnimate();
        });
      this.todayNutrition.isCompleted = true;
      $('#confirmationModal').modal('hide');
      this.dataService
        .update(`api/Kids/KidsExercise/MarkExerciseAsDone`, {
          childId: this.todayNutrition.childId,
          activityHistoryId: this.todayNutrition.activityHistoryId,
          stars: this.todayNutrition.stars,
        })
        .subscribe((Exercise) => {});
    }
  }

  public startAnimate(): void {
    const star = this.renderer.createElement('div');
    this.renderer.addClass(star, 'star');
    this.renderer.addClass(star, 'center-star');
    this.renderer.setStyle(star, 'top', `${Math.random() * 100}vh`);
    this.renderer.setStyle(star, 'left', `${Math.random() * 100}vw`);
    this.renderer.appendChild(this.el.nativeElement, star);

    setTimeout(() => {
      this.renderer.setStyle(star, 'opacity', '0');
      setTimeout(() => {
        this.renderer.removeChild(this.el.nativeElement, star);
      }, 2000);
    }, Math.random() * 5000);
  }
}
