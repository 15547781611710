import { Component } from '@angular/core';
import {
  NgbModal,
  NgbModalRef,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { DataService } from './services/data.service';
import { ChildNotification } from './app-models/notifications.model';
import { UserService } from './services/user.service';
import { NotificationService } from './services/notification.service';
import { InspirationalMessage } from './main/inspirational-modal/model/inspirational-message.model';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { WelcomeModalComponent } from './shared/welcome-modal/welcome-modal.component';
import { SubscriptionExpirationComponent } from './shared/subscription-expiration/subscription-expiration.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'portal';
  public faBell = faBell;
  public childNotifications: ChildNotification[] = [];
  public showNotifications: boolean = false;
  constructor(
    public ngbModal: NgbModal,
    private dataService: DataService,
    private userService: UserService,
    private notificationService: NotificationService
  ) {
    this.userService.authService.user$.subscribe((res) => {
      if (!!res && res?.sub) {
        this.getChildSubscriptionValidity();
      }
    });
  }

  private getInspirationalMessage(): void {
    this.dataService
      .getAll<InspirationalMessage>(
        `api/Kids/KidsActivities/GetRandomInspirationalMessages`
      )
      .subscribe((data) => {
        this.notificationService.showInspirationalMessage(
          data.message ?? '',
          'Inispirational Message'
        );
      });
  }

  private getNotifications(): void {
    this.dataService
      .getAll<ChildNotification[]>(
        `api/Kids/KidsActivities/GetNotificationsByChildId/${this.userService.userProfile.userId}`
      )
      .subscribe((data) => {
        if (data.length > 0) {
          this.childNotifications = data;
        }
      });
  }

  public getChildSubscriptionValidity(): void {
    this.dataService
      .getById(
        `api/Kids/KidsActivities/GeChildSubscriptionValidity`,
        this.userService?.userProfile.userId
      )
      .subscribe((validity: any) => {
        if (validity) {
          this.openWelcomeModal();
          this.getInspirationalMessage();
          this.getNotifications();
        } else {
          this.openSubscriptionExpirationModal();
        }
      });
  }
  private openWelcomeModal(): void {
    const modalOptions: NgbModalOptions = {
      size: 'lg',
      centered: true,
    };
    const modalRef: NgbModalRef = this.ngbModal.open(
      WelcomeModalComponent,
      modalOptions
    );
    modalRef.componentInstance.data = this.userService.userProfile.firstName;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  private openSubscriptionExpirationModal(): void {
    const modalOptions: NgbModalOptions = {
      size: 'lg',
      centered: true,
    };
    const modalRef: NgbModalRef = this.ngbModal.open(
      SubscriptionExpirationComponent,
      modalOptions
    );
    modalRef.result.then(
      (result) => {
        this.userService.logout();
      },
      (reason) => {
        this.userService.logout();
      }
    );
  }
}
