<div class="row">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <div class="col-lg-2 col-md-2 col-sm-2 col-3">
        <a routerLink="/">
          <img
            src="/assets/img/kid-gate-logo.png"
            alt="logo"
            class="img-fluid logo"
        /></a>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="toggleNavbar()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-between"
        id="navbarNavDropdown"
        [ngClass]="{ show: isNavbarOpen }"
      >
        <ul class="navbar-nav">
          <li class="nav-item header-btn">
            <a
              class="nav-link btn btn-kg-color-yellow theme-btn mt-2"
              aria-current="page"
              type="button"
              (click)="toggleNavbar()"
              routerLink="/activities"
              ><span>Activities</span></a
            >
          </li>
          <li class="nav-item header-btn">
            <a
              class="nav-link btn btn-kg-color-blue theme-btn mt-2"
              aria-current="page"
              type="button"
              (click)="toggleNavbar()"
              routerLink="/games"
              ><span>Games</span></a
            >
          </li>
          <li class="nav-item header-btn">
            <a
              class="nav-link btn btn-kg-color-pink theme-btn mt-2"
              aria-current="page"
              type="button"
              (click)="toggleNavbar()"
              routerLink="/exercise"
              ><span>Exercise</span></a
            >
          </li>
          <li class="nav-item header-btn">
            <a
              class="nav-link btn btn-kg-color-yellow theme-btn mt-2"
              aria-current="page"
              type="button"
              (click)="toggleNavbar()"
              routerLink="/nutrition"
              ><span>Nutrition</span></a
            >
          </li>
          <li class="nav-item header-btn">
            <a
              class="nav-link btn btn-kg-color-blue theme-btn mt-2"
              aria-current="page"
              type="button"
              (click)="toggleNavbar()"
              routerLink="/entertainment"
              ><span>Entertainment</span></a
            >
          </li>
          <li class="LogoutAndProfileGroup nav-item header-btn">
            <a
              class="nav-link btn btn-kg-color-orange theme-btn mt-2"
              aria-current="page"
              type="button"
              (click)="toggleNavbar()"
              routerLink="/profile"
              ><span>Profile</span></a
            >
            <a
              class="nav-link btn btn-kg-color-blue theme-btn mt-2"
              aria-current="page"
              type="button"
              (click)="onClickLogOut()"
              ><span>Logout</span></a
            >
          </li>
        </ul>

        <div class="UserAvatar dropdown login">
          <div ngbDropdown class="dropstart" placement="left">
            <button class="avatar" id="dropdownButton" ngbDropdownToggle>
              {{ this.userService?.userProfile?.initials || "" | uppercase }}
            </button>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownButton"
              ngbDropdownMenu
            >
              <button class="dropdown-item" (click)="onClickLogOut()">
                <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
              </button>
              <button class="dropdown-item" routerLink="/profile">
                <i class="fa fa-user-circle" aria-hidden="true"></i> Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
