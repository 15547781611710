<ng-container
  *ngIf="todayAssignedActivity || this.isInProgress; else notAssignedActivity"
>
  <div id="activities-component">
    <div class="weekDays">
      <ng-container *ngFor="let item of weekDays">
        <div
          class="title"
          [class.active]="todayAssignedActivity?.dayOfWeek == item"
        >
          {{ item }}
        </div>
      </ng-container>
    </div>

    <div class="activity-detail-section">
      <div class="heading">
        {{ todayAssignedActivity?.name }}
      </div>
      <div class="description">
        {{ todayAssignedActivity?.description }}
      </div>
    </div>
    <div class="action-section">
      <div class="star-section">
        <img src="/assets/img/star.svg" />
        <p *ngIf="!todayAssignedActivity?.image" class="text-left">
          Complete this activity and earn
          {{ todayAssignedActivity?.stars }} points
        </p>
        <p *ngIf="todayAssignedActivity?.image" class="text-left">
          Congratulations! You Earned {{ todayAssignedActivity?.stars }} points
        </p>
      </div>
      <div class="paint-wrapper">
        <div class="btn-tools">
          <button class="btn btn-primary" (click)="saveDrwing()">Save</button>
          <button class="btn btn-primary" (click)="closePainterro()">
            Add
          </button>
        </div>
      </div>
    </div>
    <div id="painterroContainer"></div>
  </div>
</ng-container>

<ng-template #notAssignedActivity>
  <div class="splash-row">
    <div class="speakerSplash">
      <img src="assets/img/NoActivity.png" *ngIf="currentDay !== 'Saturday'" />
      <img
        src="assets/img/HolidayNoActivity.png"
        *ngIf="currentDay === 'Saturday'"
      />
    </div>
  </div>
</ng-template>
