<div style="margin-top: 1rem">
  <strong>Type of information</strong>
</div>
<div>
  We only collect information that is essential and crucial for the website,
  such as name, address, or log-in information, card, email address and we do
  this to ensure that our users can effectively use and take advantage of all
  our services.
</div>

<div style="margin-top: 1rem">
  <strong>Obtaining information</strong>
</div>
<div>
  Only parents and the admin or other authority of our website have access to
  the information provided by the user.
</div>

<div style="margin-top: 1rem">
  <strong>Security</strong>
</div>
<div>
  We take reasonable measures to protect children's personal information from
  unauthorized access, disclosure, alteration, or destruction. However, no data
  transmission or storage system is completely secure, and we cannot guarantee
  the security of children's personal information.
</div>

<div style="margin-top: 1rem">
  <strong>Changes to this Privacy Policy</strong>
</div>
<div>
  We may update this Privacy Policy to reflect changes in our practices or legal
  requirements. We will notify parents or guardians of any material changes and
  seek their consent when necessary.
</div>

<div style="margin-top: 1rem">
  <strong>Contact information</strong>
</div>
<div>
  You can reach us directly at
  <strong>kidsgate.admin@kidsgate.org</strong> if you have any questions or
  concerns about our privacy policies or are having any issues.
</div>
