import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  constructor(public userService: UserService) {}
  ngOnInit(): void {}
  faUser = faUser;
  isNavbarOpen = false;

  toggleNavbar() {
    this.isNavbarOpen = !this.isNavbarOpen;
  }
  public onClickLogOut(): void {
    this.userService.logout();
  }
}
