import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Exercise } from './model/exercise.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from '../../services/user.service';
import { interval, take } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss'],
})
export class ExerciseComponent implements OnInit {
  public navigationButtonClass: string[] = [];
  public cardData: Exercise[] = [];
  public todayAssignExercise!: Exercise;
  public safeUrl: SafeResourceUrl = '';
  public url?: string | null | undefined;
  public image: any = null;
  public isVideo: boolean = true;
  public title?: string | null;
  public description?: string | null;
  public name?: string | null;
  public stars?: number | null;
  public starsEarned?: number | null;
  public dayOfWeek?: string | null;
  public isCompleted: boolean = false;
  public childId?: number | null;
  public currentDay?: string;
  public activityHistoryId?: number | null;
  public weekDays: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  constructor(
    private sanitizer: DomSanitizer,
    public dataService: DataService,
    public user: UserService,
    public notification: NotificationService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    const currentDate = new Date();
    this.currentDay = currentDate.toLocaleDateString('en-US', {
      weekday: 'long',
    });
  }
  ngOnInit(): void {
    this.getExerciseByDay();
  }
  public getExerciseByDay(): void {
    this.notification.showSpinner.next(true);
    this.dataService
      .getById<Exercise[]>(
        `api/Kids/KidsExercise/GetExerciseByDay`,
        this.user.userProfile.userId
      )
      .subscribe((data) => {
        this.notification.showSpinner.next(false);

        this.cardData = data;
        if (this.cardData.length > 0) {
          console.log('response from exercise', data[0]);
          this.childId = data[0].childId;
          this.activityHistoryId = data[0].activityHistoryId;
          this.todayAssignExercise = data[0];
          this.name = this.cardData[0].name;
          this.description = this.cardData[0].description;
          this.isCompleted = this.cardData[0].isCompleted;
          this.url = this.cardData[0].videoUrl || '';
          if (this.url.length > 0) {
            this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.url + '?rel=0'
            );
          } else {
            this.isVideo = false;
            if (this.cardData[0].image !== null) {
              this.image = 'data:image/png;base64,' + this.cardData[0].image;
            }
          }
          this.stars = this.cardData[0].stars;
          this.dayOfWeek = this.cardData[0].dayOfWeek;
        }
      });
  }

  private openModal(): void {
    $('#confirmationModal').modal('show');
  }
  public cancelModal(): void {
    this.isCompleted = false;
    $('#confirmationModal').modal('hide');
  }
  public onCheckboxChange(): void {
    if (!this.isCompleted) {
      this.openModal();
    }
  }

  public onConfirm(): void {
    if (this.cardData.length > 0) {
      interval(20)
        .pipe(take(200))
        .subscribe(() => {
          this.startAnimate();
        });
      this.isCompleted = true;
      $('#confirmationModal').modal('hide');
      this.dataService
        .update(`api/Kids/KidsExercise/MarkExerciseAsDone`, {
          childId: this.childId,
          activityHistoryId: this.activityHistoryId,
          stars: this.stars,
        })
        .subscribe((Exercise) => {});
    }
  }
  public startAnimate(): void {
    const star = this.renderer.createElement('div');
    this.renderer.addClass(star, 'star');
    this.renderer.addClass(star, 'center-star');
    this.renderer.setStyle(star, 'top', `${Math.random() * 100}vh`);
    this.renderer.setStyle(star, 'left', `${Math.random() * 100}vw`);
    this.renderer.appendChild(this.el.nativeElement, star);

    setTimeout(() => {
      this.renderer.setStyle(star, 'opacity', '0');
      setTimeout(() => {
        this.renderer.removeChild(this.el.nativeElement, star);
      }, 2000);
    }, Math.random() * 5000);
  }
}
