<ng-container *ngIf="todayAssignExercise; else notAssignedExerise">
  <div class="row">
    <div class="heading text-center pt-16 color-deepblue">Fitness</div>
    <p class="pt-16">
      Challenge Your Body, Transform Your Mind: Get Fit Today!
    </p>
  </div>

  <div class="weekDays pt-16">
    <ng-container *ngFor="let item of weekDays">
      <div
        class="title"
        [class.active]="todayAssignExercise?.dayOfWeek == item"
      >
        {{ item }}
      </div>
    </ng-container>
  </div>

  <div class="mt-16 video-section">
    <div class="row bgcard">
      <div class="col-lg-6 col-md-6 col-sm-6 left-section">
        <div class="card-body">
          <h4 class="card-title text-center font-w-700 color-deepblue">
            {{ name }}
          </h4>
          <p class="card-text pt-16">
            {{ description }}
          </p>
        </div>
        <div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              [(ngModel)]="isCompleted"
              [disabled]="isCompleted"
              (click)="onCheckboxChange()"
            />
            <p class="form-check-label text-left">
              Did you complete this exercise?
            </p>
          </div>

          <div class="col-lg-12 star-section">
            <img src="/assets/img/star.svg" />
            <p *ngIf="!isCompleted" class="text-left">
              Complete this activity and earn {{ stars }} points
            </p>
            <p *ngIf="isCompleted" class="text-left">
              Congratulations! You Earned {{ stars }} points
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="safeUrl && isVideo">
        <iframe
          class="e2e-iframe-untrusted-src"
          title="Exer"
          [src]="safeUrl"
          sandbox="allow-same-origin allow-scripts"
        ></iframe>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="!isVideo">
        <div *ngIf="image">
          <img [src]="image" alt="Selected Image" style="width: 100%" />
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #notAssignedExerise>
  <div class="splash-row">
    <div class="speakerSplash">
      <img src="assets/img/NoExercise.png" *ngIf="currentDay !== 'Saturday'" />
      <img
        src="assets/img/HolidayNoExercise.png"
        *ngIf="currentDay === 'Saturday'"
      />
    </div>
  </div>
</ng-template>
<div
  class="modal fade"
  id="confirmationModal"
  back-drop="static"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  data-bs-backdrop="static"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Confirmation</h5>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="cancelModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">Are you sure you completed this exercise?</div>
      <div class="modal-footer">
        <button type="button" (click)="cancelModal()" class="btn btn-secondary">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="onConfirm()">
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
