import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private _url: string;
  constructor(
    private httpClient: HttpClient,
    private notification: NotificationService
  ) {
    this._url = environment.apiUrl;
  }
  public getAll<T>(endpoint: string): Observable<T> {
    return this.httpClient.get<T>(`${this._url}/${endpoint}`);
  }

  public getAllWithQueryParameters<T>(
    endpoint: string,
    queryParameters: string
  ): Observable<T[]> {
    const options = { params: new HttpParams({ fromString: queryParameters }) };
    return this.httpClient.get<T[]>(`${this._url}/${endpoint}`, options);
  }

  public getById<T>(endpoint: string, id?: string): Observable<T> {
    return this.httpClient.get<T>(`${this._url}/${endpoint}/${id}`);
  }

  public add<T>(endpoint: string, item: T): Observable<T> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post<T>(`${this._url}/${endpoint}`, item, {
      headers: headers,
    });
  }

  public addFile<T>(endpoint: string, item: T): Observable<T> {
    let headers = new HttpHeaders();
    // headers.append('Content-Type', 'multipart/form-data');
    return this.httpClient.post<T>(`${this._url}/${endpoint}`, item, {
      headers: headers,
    });
  }
  public update<T>(endpoint: string, item: T): Observable<T> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient
      .put<T>(`${this._url}/${endpoint}`, item, {
        headers: headers,
      })
      .pipe(catchError(this.handleError));
  }

  public deleteById(endpoint: string, id: number): Observable<unknown> {
    return this.httpClient.delete(`${this._url}/${endpoint}/${id}`);
  }

  public getCurrentDateInISO8601Format(): string {
    // yyyy-mm-dd
    const dt = new Date();
    const year = dt.getFullYear();
    const month = (dt.getMonth() + 1).toString().padStart(2, '0');
    const day = dt.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  public getISO8601Date(date: Date): string {
    // yyyy-mm-dd
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }
}
