import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-subscription-expiration',
  templateUrl: './subscription-expiration.component.html',
  styleUrls: ['./subscription-expiration.component.scss'],
})
export class SubscriptionExpirationComponent implements OnInit {
  constructor(private ngbModal: NgbModal) {}

  ngOnInit() {}

  dismissModal() {
    this.ngbModal.dismissAll('dismiss');
  }
}
