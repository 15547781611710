import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';
import { ChildProfile } from './model/ChildProfile';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  childProfile: ChildProfile | null = null;

  constructor(
    private userService: UserService,
    private dataService: DataService,
    private notification: NotificationService
  ) {}
  ngOnInit(): void {
    this.getProfileInfo();
  }

  private getProfileInfo(): void {
    this.notification.showSpinner.next(true);
    this.dataService
      .getAll<ChildProfile>(
        `api/Kids/KidsActivities/GetChildProfile/${this.userService.userProfile.userId}`
      )
      .subscribe((data) => {
        if (data) {
          this.childProfile = data;
        }
        this.notification.showSpinner.next(false);
      });
  }
}
