import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss'],
})
export class GamesComponent implements OnInit {
  public feedback: string = '';
  public isLoading: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    public dataService: DataService,
    public user: UserService,
    public notification: NotificationService
  ) {
    this.selectedOption = !!this.gameList && this.gameList[0];
    this.sanitizeUrl();
  }
  ngOnInit(): void {}

  public safeUrl?: SafeResourceUrl =
    'assets/games/fruit-slice-frenzy-kidgates/index.html';
  public gameList = [
    {
      id: 1,
      title: 'Fruit Slice',
      url: 'assets/games/fruit-slice-frenzy-kidgates/index.html',
      thumbnil:
        'assets/games/fruit-slice-frenzy-kidgates/media/graphics/sprites/title/title.png',
    },

    {
      id: 2,
      title: 'Tankers Drop',
      url: 'assets/games/TowerGame/index.html',
      thumbnil: 'assets/games/TowerGame/assets/tankerThumbnail.png',
    },

    {
      id: 3,
      title: 'Highway Race',
      url: 'assets/games/HighwayRace/dist/index.html',
      thumbnil: 'assets/games/HighwayRace/dist/highwayrace.jpg',
    },
    {
      id: 4,
      title: 'Rabbit Run',
      url: 'assets/games/RabbitRun/index.html',
      thumbnil: 'assets/games/RabbitRun/rabbitrun.jpg',
    },
    {
      id: 5,
      title: 'Image Puzzle',
      url: 'assets/games/ImagePuzzle/index.html',
      thumbnil: 'assets/games/ImagePuzzle/images/puzzleThumbnail.png',
    },
    {
      id: 6,
      title: 'Tic Tac',
      url: 'assets/games/TicTac/index.html',
      thumbnil: 'assets/games/TicTac/tictoeThumbnail.png',
    },
  ];
  public selectedOption = {
    id: 1,
    url: 'assets/fruit-slice-frenzy-kidgates/index.html',
    title: 'Fruit Slice',
  };
  public setGame(item: any) {
    this.selectedOption = item;
    this.sanitizeUrl();
    this.feedback = '';
  }
  public sanitizeUrl() {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.selectedOption.url
    );
  }
  public onSubmitFeedback(): void {
    if (this.feedback !== '') {
      this.isLoading = true;
      this.dataService
        .add(`api/Kids/KidsGames/SaveFeedback`, {
          GameId: this.selectedOption.id,
          Feedback: this.feedback,
          Auth0Id: this.user.userProfile.userId,
        })
        .subscribe(() => {
          this.isLoading = false;
          this.feedback = '';
          this.notification.showSuccessMessage('Thank you For Feedback', '');
        });
    }
  }
}
