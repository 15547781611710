<div id="nutrition-component">
  <ng-container
    *ngIf="
      (todayNutrition && todayNutrition.activityHistoryId > 0) ||
        this.isInProgress;
      else notAssignedActivity
    "
  >
    <div id="activities-component">
      <div class="weekDays">
        <ng-container *ngFor="let item of weekDays">
          <div class="title" [class.active]="todayNutrition?.dayOfWeek == item">
            {{ item }}
          </div>
        </ng-container>
      </div>

      <div class="activity-detail-section mb-2">
        <div class="heading">Nutrition Activity!</div>
        <div class="description text-center">
          Fuel Your Body with Good Nutrition. Nourish Your Mind, Body, and Soul.
        </div>
      </div>

      <div class="video-section">
        <div class="row bgcard">
          <div class="col-lg-7 col-md-7 col-sm-6 left-section">
            <div>
              <h5
                class="card-title text-center font-w-700 color-deepblue text-width"
              >
                {{ todayNutrition?.name }}
              </h5>
              <div style="margin-left: 2rem">
                <p class="card-text text-left pt-16 text-width">
                  {{ todayNutrition?.description }}
                </p>
              </div>
              <ul class="pt-16">
                <ng-container *ngFor="let item of recipeSteps">
                  <li>
                    <p class="card-text text-left text-width">
                      {{ item }}
                    </p>
                  </li>
                </ng-container>
              </ul>
              <!-- <div style="width: 70%"> -->
              <div class="alert" style="background-color: #b1caf4">
                <strong>Allergy Disclaimer!</strong> Skip the ingredient incase
                you are allergic to any of these and you can choose one of your
                choice.
              </div>
              <!-- </div> -->
            </div>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-6">
            <div class="imageSection">
              <div *ngIf="todayNutrition.image">
                <img
                  [src]="todayNutrition.image"
                  class="selectedImage"
                  alt="Selected Image"
                />
              </div>
            </div>
          </div>
          <div class="row flex justify-content-center mt-5">
            <div class="col-12 col-md-4">
              <h5 class="card-title text-center font-w-700 color-deepblue">
                SHARE THE FLAVOR, UPLOAD THE MEAL
              </h5>
              <div class="upload-imageSection mt-3">
                <div *ngIf="selectedImage">
                  <img
                    [src]="selectedImage"
                    class="selectedImage"
                    alt="Selected Image"
                  />
                </div>
              </div>
              <div
                class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm"
              >
                <input
                  id="upload"
                  type="file"
                  accept="image/*"
                  (change)="onFileSelected($event)"
                  class="form-control border-0"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-primary rounded-pill px-4"
                    [disabled]="!selectedImage"
                    (click)="onSubmit()"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                [(ngModel)]="todayNutrition.isCompleted"
                [disabled]="todayNutrition.isCompleted"
                (click)="onCheckboxChange()"
              />
              <p class="form-check-label text-left">
                Did you complete this Nutrition Activity?
              </p>
            </div>
            <div class="col-lg-12 star-section">
              <img src="/assets/img/star.svg" />
              <p *ngIf="!todayNutrition.isCompleted" class="text-left">
                Complete this activity and earn
                {{ todayNutrition.stars }} points
              </p>
              <p *ngIf="todayNutrition.isCompleted" class="text-left">
                Congratulations! You Earned {{ todayNutrition?.stars }} points
              </p>
            </div>
          </div>
          <div class="questionSection mt-5">
            <div *ngIf="todayNutrition?.question !== ''">
              <p class="color-deepblue text-uppercase">
                {{ todayNutrition?.question }}
              </p>
            </div>
            <div class="col-12 col-md-4">
              <div
                class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm"
              >
                <input
                  id="answer"
                  type="text"
                  [(ngModel)]="todayNutrition.answer"
                  class="form-control border-0"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-primary rounded-pill px-4"
                    [disabled]="
                      todayNutrition.answer === '' ||
                      todayNutrition.answer === null ||
                      isLoading
                    "
                    (click)="onSubmitAnswer()"
                  >
                    <span
                      *ngIf="isLoading"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span *ngIf="!isLoading">Send</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #notAssignedActivity>
    <div class="splash-row">
      <div class="speakerSplash">
        <img
          src="assets/img/NoNutrition.png"
          *ngIf="currentDay !== 'Saturday'"
        />
        <img
          src="assets/img/HolidayNoNutrition.png"
          *ngIf="currentDay === 'Saturday'"
        />
      </div>
    </div>
  </ng-template>
</div>
<div
  class="modal fade"
  id="confirmationModal"
  back-drop="static"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  data-bs-backdrop="static"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Confirmation</h5>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="cancelModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you completed this Nutrition Activity?
      </div>
      <div class="modal-footer">
        <button type="button" (click)="cancelModal()" class="btn btn-secondary">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="onConfirm()">
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
