<div id="inspirationalModal" #inspirationalModal>

    <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">
            Inspirational Message
        </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismissModal()"></button>
    </div>
    <div class="modal-body">

        <div class="row">
            <div class="col-sm-12">
                {{ inspirationalMessage?.message}}
            </div>
        </div>

    </div>

</div>