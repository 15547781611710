import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InspirationalMessage } from './model/inspirational-message.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-inspirational-modal',
  templateUrl: './inspirational-modal.component.html',
  styleUrls: ['./inspirational-modal.component.scss'],
})
export class InspirationalModalComponent {
  @Input() public inspirationalMessage: InspirationalMessage;
  constructor(
    public notificationService: NotificationService,
    private ngbModal: NgbModal
  ) {}

  dismissModal() {
    this.ngbModal.dismissAll();
  }
}
