import { Component, ElementRef, ViewChild } from '@angular/core';
import { Entertainment, RewardStatus } from './model/entertainment.model';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { faL } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-entertainment',
  templateUrl: './entertainment.component.html',
  styleUrls: ['./entertainment.component.scss'],
})
export class EntertainmentComponent {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  animationDisplay = 'none';
  public entertainmentList: Entertainment[] = [];
  public rewardStatus = RewardStatus;
  constructor(
    public dataService: DataService,
    public user: UserService,
    public notification: NotificationService
  ) {}
  ngOnInit(): void {
    this.getEntertainment();
  }

  private getEntertainment(): void {
    this.notification.showSpinner.next(true);
    this.dataService
      .getById<Entertainment[]>(
        'api/portal/KidsEntertainment/GetChildRewards',
        this.user.userProfile.userId
      )
      .subscribe((entertainment) => {
        console.log('Entertainment', entertainment);
        this.notification.showSpinner.next(false);
        this.entertainmentList = entertainment;
      });
  }

  private MarkAsCollected(rewardId: number): void {
    this.dataService
      .add('api/portal/KidsEntertainment/MarkRewardAsCollected', {
        ChildId: this.user.userProfile.userId,
        RewardId: rewardId,
      })
      .subscribe((entertainment) => {
        this.playAnimation();
        this.notification.showSuccessMessage(
          'Congratulations! You collected a Coupon. Copy and Save it.',
          ''
        );
      });
  }

  collectCoupon(rewardId: number) {
    const reward = this.entertainmentList.find((r) => r.id === rewardId);
    if (reward) {
      reward.status = this.rewardStatus.Collected;
      this.MarkAsCollected(rewardId);
    }
  }

  playAnimation() {
    this.animationDisplay = 'block';
    this.videoPlayer.nativeElement.play();
  }

  removeAnimation() {
    this.animationDisplay = 'none';
  }
}
