<div>
  Please read these Terms and Conditions carefully before using
  <a href="https://www.kidsgate.org/" target="_blank">KidsGate</a>
</div>
<div>
  By accessing or using the Website, you agree to be bound by these Terms and
  Conditions. If you do not agree to these Terms and Conditions, please do not
  use this Website.
</div>

<div style="margin-top: 1rem">
  <strong>1. Age Requirement</strong>
</div>
<div>
  Users of this Website must be under the age of 13. If you are 13 years of age
  or older, please do not use this Website.
</div>

<div style="margin-top: 1rem">
  <strong>2. Parental Consent</strong>
</div>
<div>
  By allowing your child to access and use this Website, you affirm that you are
  their parent or legal guardian and consent to their use of the Website,
  subject to these Terms and Conditions.
</div>

<div style="margin-top: 1rem">
  <strong>3. Content and Services</strong>
</div>
<div>
  Our website offers age-appropriate content, games, and services for children.
  While we make every effort to ensure the content is safe and suitable, we do
  not guarantee that all content will be free from errors or omissions. We
  reserve the right to modify or remove content and services at any time without
  notice.
</div>

<div style="margin-top: 1rem">
  <strong>4. Passwords and Security.</strong>
</div>
<div>
  You agree that you will not share your account or account information with
  others. You are responsible for taking reasonable steps to maintain the
  confidentiality of your username and password, and you are responsible for all
  activities under your account that you can reasonably control. You agree to
  promptly notify us of any unauthorized use of your username, password, or
  other account information, or of any other breach of security that you become
  aware of involving your account.
</div>
<div style="margin-top: 1rem">
  <strong>5. Privacy</strong>
</div>
<div>
  We are committed to protecting the privacy of children. Please refer to our
  Privacy Policy (link here) for detailed information on how we collect, use,
  and safeguard personal information.
</div>

<div style="margin-top: 1rem">
  <strong>6. User Conduct</strong>
</div>
<div>
  Users are expected to conduct themselves in a respectful and safe manner while
  using our website.
</div>
<div>
  Do not share personal information, including full names, addresses, phone
  numbers, or other identifiable information.
</div>
<div>
  Do not engage in bullying, harassment, or any harmful behavior towards others.
</div>
<div>Report any inappropriate content or behavior to us immediately.</div>

<div style="margin-top: 1rem">
  <strong>7. Parental Supervision</strong>
</div>
<div>
  Parents and legal guardians are encouraged to monitor their child&apos;s
  online activities and supervise their use of this Website.
</div>

<div style="margin-top: 1rem">
  <strong>8. Intellectual Property</strong>
</div>
<div>
  All content, including text, images, and multimedia on this Website, is
  protected by copyright and other intellectual property rights. Users may not
  reproduce, distribute, or modify any content without permission.
</div>

<div style="margin-top: 1rem">
  <strong>9. Links to External Websites</strong>
</div>
<div>
  Our website may contain links to external websites. We are responsible for the
  content but not the privacy practices of these third-party websites.
</div>

<div style="margin-top: 1rem">
  <strong>10. Termination</strong>
</div>
<div>
  We reserve the right to terminate or suspend access to our website for any
  user who violates these Terms and Conditions or engages in harmful behavior.
</div>

<div style="margin-top: 1rem">
  <strong>11. Changes to Terms and Conditions</strong>
</div>
<div>
  We reserve the right to update or change these Terms and Conditions at any
  time without prior notice. Your continued use of the Website after any
  modifications signifies your acceptance of the updated Terms and Conditions.
</div>

<div style="margin-top: 1rem">
  <strong>12. Contact Us</strong>
</div>
<div>
  If you have any questions or concerns regarding these Terms and Conditions,
  please contact us at <strong></strong>
</div>
<div>
  By using this Website, you agree to comply with these Terms and Conditions. If
  you do not agree with any part of these Terms and Conditions, please do not
  use the Website and you can skip the page below.
</div>
