import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDropdown, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './header/navigation/navigation.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { GamesComponent } from './main/games/games.component';
import { ExerciseComponent } from './main/exercise/exercise.component';
import { NutritionComponent } from './main/nutrition/nutrition.component';
import { EntertainmentComponent } from './main/entertainment/entertainment.component';
import { GlobalErrorHanlderService } from './services/global-error-hanlder.service';
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';
import { CustomRouteReuseStrategy } from './routing/custom-route-reuse-strategy';
import { RouteReuseStrategy } from '@angular/router';
import { TermsOfUseComponent } from './shared/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { PaintingComponent } from './main/painting/painting.component';
import { InspirationalModalComponent } from './main/inspirational-modal/inspirational-modal.component';
import { WelcomeModalComponent } from './shared/welcome-modal/welcome-modal.component';
import { ProfileComponent } from './main/profile/profile.component';
import { SubscriptionExpirationComponent } from './shared/subscription-expiration/subscription-expiration.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavigationComponent,
    SpinnerComponent,
    PageNotFoundComponent,
    GamesComponent,
    ExerciseComponent,
    NutritionComponent,
    EntertainmentComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    PaintingComponent,
    InspirationalModalComponent,
    WelcomeModalComponent,
    ProfileComponent,
    SubscriptionExpirationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    AuthModule.forRoot({
      domain: environment.authDomain,
      clientId: environment.authClientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.authAudience,
        scope: environment.authScope,
      },
      httpInterceptor: {
        allowedList: [{ uri: `${environment.apiUrl}/*` }],
      },
    }),
  ],

  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHanlderService },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    PaintingComponent,
    NgbDropdown,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
