import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NutritionComponent } from './main/nutrition/nutrition.component';
import { ExerciseComponent } from './main/exercise/exercise.component';
import { GamesComponent } from './main/games/games.component';
import { EntertainmentComponent } from './main/entertainment/entertainment.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { PaintingComponent } from './main/painting/painting.component';
import { ProfileComponent } from './main/profile/profile.component';

const routes: Routes = [
  {
    path: 'nutrition',
    component: NutritionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'exercise',
    component: ExerciseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'activities',
    component: PaintingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'games',
    component: GamesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'painting',
    component: PaintingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'entertainment',
    component: EntertainmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: 'activities', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
