<div id="entertainmentComponent">
  <div
    class="view-container-bg celebration-confetti"
    [ngStyle]="{
      'background-image': 'url(assets/img/Entertainment/celebration.gif)'
    }"
  ></div>
  <div [style.display]="animationDisplay">
    <video #videoPlayer autoplay muted id="myVideo" (ended)="removeAnimation()">
      <source src="/assets/img/Entertainment/fireWorks.mp4" type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>
  </div>
  <div class="pt-16">
    <div>
      <div class="heading text-center pt-16 color-deepblue">OPEN AND WIN</div>
      <div class="heading-text text-center">
        <div>Your adventures deserve celebration</div>
        <div>Choose your rewards and let the magic continue</div>
      </div>
    </div>
    <div class="row pt-16">
      <ng-container *ngFor="let reward of entertainmentList">
        <div class="col-lg-3 col-md-3 col-12">
          <div
            class="card-wrapper"
            [ngClass]="{ flipped: reward.status === rewardStatus.Collected }"
          >
            <div class="card-front">
              <div class="item">
                <img
                  src="/assets/img/Entertainment/closedBox.png"
                  class="card-img"
                />
                <div class="card-text">
                  {{ reward.stars }}<br /><span style="font-size: 20px"
                    >STARS</span
                  >
                </div>
                <div class="card-button">
                  <button
                    [disabled]="reward.status === rewardStatus.Disabled"
                    (click)="collectCoupon(reward.id)"
                  >
                    <span
                      *ngIf="
                        reward.status === rewardStatus.Enabled;
                        else lockIcon
                      "
                      >OPEN</span
                    >
                    <ng-template #lockIcon>
                      <img src="/assets/img/lock.svg" />
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>

            <div class="card-back">
              <div class="item">
                <img
                  src="/assets/img/Entertainment/box4.png"
                  class="card-img"
                />
                <div class="card-text">
                  <img src="/assets/img/Entertainment/firstBoxAnimation.gif" />
                </div>
                <div class="card-button">
                  <div class="couponBox">{{ reward.coupon }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
